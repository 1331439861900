@import "../../../variable.scss";

.slider-container {
  &__card {
    cursor: pointer;
    height: 30rem;
    background-color: $white;
    padding-bottom: 2rem;
    border-radius: 20%;
    box-shadow: 5px 5px 7px rgba(0, 0, 0, 0.25),
      -5px -5px 7px rgba(0, 0, 0, 0.22);

    overflow: hidden;
    transition: all 0.2s;
    position: relative;
  }
  &__circle {
    width: 20px;
    height: 20px;
    background-color: rgba($primary-color, 0.85);
    border-radius: 50%;
    position: absolute;
    bottom: 1.5rem;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.5s ease-in-out;
  }
  &__read-more {
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: 55%;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    opacity: 0; /* To hide the text initially */
    transition: opacity 0.8s ease-in-out;
  }
  &__card:hover {
    box-shadow: 0 0 0 2px $primary-color, 5px 5px 7px rgba(0, 0, 0, 0.25),
      -5px -5px 7px rgba(0, 0, 0, 0.22);
  }
  &__card:hover &__circle {
    width: 60rem;
    height: 60rem;
    border-radius: 50%;
  }
  &__card:hover &__read-more {
    opacity: 1;
    color: white;
  }
  a:hover {
    color: white;
  }

  &__card-image-wrapper {
    width: 100%;
    height: 14.06rem;
  }
  &__card-content {
    padding: 1.5rem;
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;

    justify-content: space-around;
  }
  &__card-image {
    width: 100%;
    height: 100%;
  }
  &__card-h3 {
    text-align: center;
    color: $primary-color;
    font-size: 1.4rem;
    align-self: flex-start;
    margin-bottom: 2rem;
  }
  &__card-paragraph-ul {
    line-height: 1.5rem;
    list-style: none;
    font-size: 0.9rem;
    color: $grey3;
  }

  &__checkmark {
    margin-right: 0.8rem;
    color: $primary-color;
  }
  &__flexbox {
    display: flex;
  }
}
@media (max-width: 688px) {
  .slider-container__card {
    width: 80%;
  }
}
