@import "./variable.scss";

body {
  font-family: $font-family, $default-font-family;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 15px;
}

h3 {
  font-size: 1.25rem;
  margin-bottom: 15px;
}

.button {
  display: inline-block;
  padding: 10px 20px;
  background-color: $primary-color;
  color: $white;
  border: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.2s;

  &:hover {
    background-color: darken($primary-color, 10%);
  }

  &.secondary {
    background-color: $primary-color;
  }
}

a {
  color: $white;
  text-decoration: none;
  transition: color 0.2s;

  &:hover {
    color: $primary-color-light1;
  }
}

.form {
  input[type="text"],
  input[type="email"],
  input[type="password"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  button[type="submit"] {
    background-color: $primary-color;
    color: $white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.2s;

    &:hover {
      background-color: darken($primary-color, 10%);
    }
  }
}
