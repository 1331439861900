@import "../../../variable.scss";

.feature-card-container:hover .feature-card-border {
  opacity: 1;
}
.feature-card-interne:hover {
  opacity: 1;
}

.feature-card {
  height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba($primary-color, 0.1);
  border-radius: 10px;
  cursor: pointer;
  position: relative;
}
.feature-card-interne {
  border-radius: inherit;

  left: 0px;
  top: 0px;

  position: absolute;

  width: 100%;
  height: 100%;
  background-color: rgba($primary-color-dark2, 0.02);
  opacity: 0;

  z-index: 3;
  transition: "opacity 3500ms";
}
.feature-card-border {
  border-radius: inherit;

  background-color: rgba($primary-color-dark2, 0.02);
  left: 0px;
  top: 0px;

  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;

  z-index: 1;
  transition: "opacity 3500ms";
}

.feature-card .card-content {
  background-color: $primary-color-dark2;
  border-radius: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  inset: 1px;
  padding: 10px;
  position: absolute;
  z-index: 2;
}

.feature-icon {
  font-size: 3rem;
  margin-bottom: 10px;
  color: $primary-color;
}

.feature-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: $white;
}

.feature-description {
  font-size: 1rem;
  color: $white;
}

@media (max-width: 840px) {
  .feature-title {
    font-size: 1.4rem;
  }
  .feature-description {
    font-size: 1rem;
  }
}

@media (max-width: 534px) {
  .feature-title {
    font-size: 1.3rem;
  }
  .feature-description {
    font-size: 0.9rem;
  }
}
