@import "../../../variable.scss";

.services-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include background-style2;
  &__title {
    text-align: center;
    font-size: 3rem;
    font-weight: bold;
    margin-top: 9rem;
    margin-bottom: 8rem;
    color: $grey1;
    letter-spacing: 5px;
  }
  &__container {
    max-width: 916px;
    width: calc(100% - 20px);
    margin: auto;
    margin-bottom: 15rem;
  }
  &__card-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-column-gap: 15px;
    grid-row-gap: 25px;
    text-align: center;
  }
}

@media (max-width: 1050px) {
  .services-section__title {
    font-size: 2rem;
  }
}

@media (max-width: 780px) {
  .services-section__container {
    margin-bottom: 9rem;
  }
  .services-section__title {
    font-size: 2rem;
    margin-top: 5rem;
    margin-bottom: 3rem;
    padding: 0 1rem;
  }
}
@media (max-width: 534px) {
  .services-section__title {
    font-size: 1.6rem;
  }
}
