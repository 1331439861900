@import "../../../variable.scss";

$font-size: 280px;
$bg-color: #1e2134;

.faq-container {
  display: flex;

  flex-wrap: wrap;

  overflow: hidden;

  &__column {
    flex: 1; // Each column will occupy 50% of the width
    padding: 20px;
  }
  &__column-1 {
    @include background-style2();
    overflow: hidden;
  }
  &__column-2 {
    z-index: 15;
  }
  &__wrapper {
    position: relative;
    color: #fff;
    transform: skewY(-5deg);
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 25rem;
  }
  &__main-wrapper {
    align-self: center;
    max-width: 48rem;
    margin: auto;
  }

  &__main h3 {
    font-size: 3.1rem;
    color: white;
    margin: 1rem 0;
  }
  &__main p {
    font-size: 1.2rem;
    margin: 1rem 0;
  }
  &__bg-text-container {
    transform: translateX(-25%);
    position: absolute;
    z-index: -5;
  }
  &__animate-text {
    animation: text-scrolling 20s linear infinite;
    white-space: nowrap;
    font-size: $font-size;
    color: transparent;
    -webkit-text-stroke: 2px $primary-color;
    text-transform: uppercase;
    display: inline-block;
    line-height: 0.75;
    min-width: auto;
    font-weight: 800;
  }
}
.left-text {
  animation-direction: reverse;
}

@keyframes text-scrolling {
  0% {
    transform: translate3d(-50%, 0, 0);
  }
  100% {
    transform: translate3d(0%, 0, 0);
  }
}

@media screen and (max-width: 980px) {
  .faq-container__column {
    flex-basis: 100%; // Columns will occupy 100% width on small screens
  }
  .faq-container__main {
    margin-bottom: 3rem;
  }
  .faq-container__main h3 {
    font-size: 2rem;
  }
  .faq-container__main p {
    font-size: 1rem;
  }
}
