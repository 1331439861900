@import "../../../variable.scss";
.slick-list {
  margin: 1rem -1.8rem;
}
.slick-slide > div {
  padding: 1rem 1.8rem;
}
.slick-prev::before,
.slick-next::before {
  color: $primary-color !important;
}

.slick-prev,
.slick-next {
  z-index: 9999;
}
.slider-container {
  margin: 15rem 0 20rem;
}
.card-container {
  width: 90%;
  margin: auto;
  margin-top: -7rem;
}

@media (min-width: 900px) {
  .slick-prev::before,
  .slick-next::before {
    font-size: 45px !important;
  }
  .slick-prev {
    left: -50px !important;
  }
}

@media (max-width: 820px) {
  .slider-container {
    &__card {
      width: 23.125rem !important;
      display: flex !important;
      margin: 0 auto;
    }
  }
}

@media (max-width: 688px) {
  .slick-slide > div {
    display: flex;
    justify-content: center;
  }
}
