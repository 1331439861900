.modal-container:focus {
  outline: none;
}
.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  overflow: auto;

  width: 800px;
  height: 450px;
  .iframe-container {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

@media (max-width: 880px) {
  .modal-container {
    aspect-ratio: 16 / 9;
    width: 100%;
    height: auto;
  }
}
.video {
  pointer-events: none;
}
