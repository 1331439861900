@import "../../../variable.scss";

.hero {
  max-width: 1450px;
  margin: auto;
  color: $primary-color-dark3;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  position: relative;
  margin-bottom: 130px;
  &__svg {
    position: absolute;
    z-index: -1;
    width: 41rem;
    height: auto;
    left: 0rem;
    bottom: 0rem;
  }

  &__main {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    justify-content: space-between;
    align-items: center;
    gap: 5rem;
    margin: 5rem 0;
  }

  &__content {
    max-width: 55rem;
    margin: 0 auto;
    padding: 1.25rem;
    z-index: 2;
  }
  &__content a {
    display: flex;
    justify-content: center;
    margin: auto;
  }
  &__box {
    position: relative;
    max-width: 30rem;
    height: auto;
    display: block;
  }
  &__title {
    font-weight: 600;
    font-size: 3.4rem;
    margin: 2.5rem 0;
    color: $primary-color-dark2;
  }
  &__description {
    font-size: 1.4rem;
    margin-bottom: 55px;
    line-height: 2.5rem;
    font-weight: 500;
    color: $primary-color-dark3;
  }
}

////////////////////////////////////////////

$height: 15rem;
$width: 15rem;
$zAxis: 11.25rem;
.box-container {
  position: relative;
  height: $height;
  width: 100%;
  left: 0;
  transition: transform 3s ease;
}

.wrapper {
  perspective: 800px;
  perspective-origin: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: $height;
  width: $height;
}

.box-area {
  width: 100%;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  animation: rotateCube 20s infinite linear;
}
.box-image {
  width: 100%;
  height: 100%;
}
@keyframes rotateCube {
  0%,
  100% {
    transform: rotateX(0deg) rotateY(0deg);
  }
  12.5% {
    transform: rotateX(0deg) rotateY(45deg);
  }
  25% {
    transform: rotateX(0deg) rotateY(90deg);
  }
  37.5% {
    transform: rotateX(0deg) rotateY(135deg);
  }
  50% {
    transform: rotateX(90deg) rotateZ(0deg);
  }
  62.5% {
    transform: rotateX(90deg) rotateZ(45deg);
  }
  75% {
    transform: rotateX(90deg) rotateZ(90deg);
  }
  87.5% {
    transform: rotateX(90deg) rotateZ(135deg);
  }
}
.box {
  background-color: $primary-color-dark3;
  opacity: 0.95;
  background-size: cover;
  background-repeat: no-repeat;
  height: $height;
  width: $width;
  position: absolute;
}

.box1 {
  transform: rotateX(90deg) translateZ($zAxis);
}

.box2 {
  transform: rotateY(90deg) translateZ($zAxis);
}

.box3 {
  transform: translateZ($zAxis);
}

.box4 {
  transform: rotateY(180deg) translateZ($zAxis);
}

.box5 {
  transform: rotateY(-90deg) translateZ($zAxis);
}

.box6 {
  transform: rotateX(-90deg) translateZ($zAxis);
}
////////////////////
@media (max-width: 1420px) {
  .hero {
    &__main {
      grid-template-columns: 1fr;
    }
    &__box {
      margin: 5rem auto 0;
    }
    .box1 {
      transform: rotateX(90deg) translateZ(120px);
    }

    .box2 {
      transform: rotateY(90deg) translateZ(120px);
    }

    .box3 {
      transform: translateZ(120px);
    }

    .box4 {
      transform: rotateY(180deg) translateZ(120px);
    }

    .box5 {
      transform: rotateY(-90deg) translateZ(120px);
    }

    .box6 {
      transform: rotateX(-90deg) translateZ(120px);
    }
  }
}
@media (max-width: 850px) {
  .hero {
    &__logo {
      margin-top: 1.5rem;
    }
    &__title {
      font-size: 1.9rem;
    }
    &__description {
      font-size: 1.2rem;
      margin: auto;
      margin-bottom: 2rem;
    }
    &__content {
      padding: 0;
      width: 80dvw;
    }
    &__main {
      margin-top: 0;
    }
  }
}

@media (max-width: 660px) {
  .hero {
    &__title {
      font-size: 1.6rem;
    }
    &__description {
      font-size: 1rem;
      margin-bottom: 2rem;
      line-height: 2rem;
    }
    &__wave {
      height: 44px;
    }
    &__logo {
      width: 6.5rem;
      height: 6.5rem;
    }
  }
}
@media (max-width: 400px) {
  .hero {
    &__logo {
      margin-top: 0;
      width: 5rem;
      height: 5rem;
    }
    &__hero-title {
      font-size: 1.4rem;
    }
  }
}
