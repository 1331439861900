@import "../../../variable.scss";

.scroll-to-top-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: $primary-color;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  z-index: 1000;
}

.scroll-to-top-btn:hover {
  background-color: #0056b3;
}

.arrow-icon {
  margin-top: 1px;
}
