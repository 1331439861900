// Import font from Google Font ("Montserrat" font example)
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primary-color: #2784b5;
$primary-color-light1: lighten($primary-color, 10%);
$primary-color-light2: lighten($primary-color, 15%);
$primary-color-dark1: darken($primary-color, 10%);
$primary-color-dark2: darken($primary-color, 15%);
$primary-color-dark3: darken($primary-color, 20%);

$grey1: #dee2e6;
$grey2: #757575;
$grey3: #343a40;

$white: #f4f8f6;
$black: #0f1010;

$default-font-family: "Arial", sans-serif;
$font-family: "Montserrat";

$success: #4caf50;
$alert: #f44336;

@mixin background-style1() {
  background-color: white;
  background: linear-gradient(rgba(white, 0.95), rgba(white, 0.95)),
    url("./img/background1.jpg") no-repeat center center fixed;
  background-attachment: fixed;
  background-size: cover;
}
@mixin background-style2() {
  background-color: black;

  background: linear-gradient($primary-color-dark1, rgba(#26353400, 0.95)),
    url("./img/background2.png") no-repeat center center fixed;
  background-attachment: fixed;
  background-size: cover;
}

@mixin titleH2($size) {
  font-size: $size;
  letter-spacing: 7px;
  line-height: 1.3;
  font-weight: 600;
  color: $primary-color;
}
