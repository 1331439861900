@import "../../../variable.scss";
.language-switcher {
  position: relative;

  &__container {
    position: relative;
    display: inline-block;
  }

  &__selected {
    cursor: pointer;
    padding: 8px;
    border-radius: 4px;
  }
  &__top {
    top: -300% !important;
  }
  &__options {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 10000;
    color: black;
    li {
      padding: 8px;
      cursor: pointer;
      transition: background-color 0.5s;

      &:hover {
        background-color: $primary-color-light1;
        color: $white;
      }
    }
  }
}
