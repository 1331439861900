.top-bar {
  background-color: #333;
  color: #fff;
  padding: 10px;

  &__row {
    max-width: 90dvw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
  }
  &__contact-info-item {
    display: flex;
    gap: 0.4rem;
    align-items: center;
  }
  &__right-element {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    justify-content: center;
  }
  &__contact-info {
    display: flex;
    align-items: center;
    gap: 2rem;
  }
}

@media (max-width: 1080px) {
  .top-bar {
    display: none;
  }
}
