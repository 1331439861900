// General CSS

.faq-accordion {
  margin: 0 auto;
  padding: 4rem;
  max-width: 52rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  &__h3 {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    line-height: 1.6;
  }
}

// Accordion CSS

.accordion {
  border-radius: 2px;
  font-size: 1.15rem;
}

.accordion__item + .accordion__item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__button {
  //   background-color: #f4f4f4;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  display: flex;
  justify-content: space-between;
  column-gap: 0.8rem;
  align-items: center;
}

.accordion__button:hover,
.accordion__button:focus {
  cursor: pointer;
  color: #03b5d2;
}

.accordion__button:after {
  content: "+";
  width: 22px;
  height: 22px;
  border: 1px solid;
  border-radius: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.accordion__button[aria-expanded="true"]::after,
.accordion__button[aria-selected="true"]::after {
  content: "-";
}

[hidden] {
  display: none;
}

.accordion__panel {
  padding: 20px;
  animation: fadein 0.35s ease-in;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
div[aria-expanded="true"] {
  border-bottom: 1px solid #03b5d2;
}

@media (max-width: 1340px) {
  .accordion {
    font-size: 1rem;
  }
}
