// @import url("https://fonts.googleapis.com/css2?family=Akronim&display=swap");
@import "../../../variable.scss";

.header {
  height: 9dvh;
  padding: 1rem;
  transition: all 0.3s ease-in-out;
  background-color: transparent;
}
.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  z-index: 1000;
  box-shadow: 0px 0px 37px -6px rgba($primary-color-dark2, 0.26);
  @include background-style1;
}
.nav-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1080px;
  margin: auto;
}

.nav-logo-container {
  width: 15rem;
}
.nav-logo-image {
  width: 100%;
}
.nav-elements {
  z-index: 10;

  &__ul {
    display: flex;
    text-align: center;
    justify-content: center;
    list-style-type: none;
    gap: 2.5rem;
  }
  &__link {
    font-size: 1.1rem;
    color: $primary-color-dark2;
    font-weight: bold;
  }
  &__link.active {
    padding: 0.5rem 1.5rem;
    background: $primary-color-dark2;
    border-radius: 40px;
    color: $white;
  }
  &__btn {
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    visibility: hidden;
    opacity: 0;
    font-size: 1.8rem;
    position: absolute;
    right: 2rem;
  }
}
@media (max-width: 1024px) {
  .nav-elements {
    position: relative;
    &__container {
      position: fixed;
      top: -100vh;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1.5rem;
      transition: 1s;
    }
    &__ul {
      flex-direction: column;
    }
    &__btn {
      visibility: visible;
      opacity: 1;
    }
    &__btn-close {
      top: 2rem;
      right: 2rem;
    }
    &__link {
      font-size: 1.7rem;
    }
  }
  .responsive_nav {
    height: 100vh;
    @include background-style1;
    transition: all 0.6s ease;
    transform: translateY(100vh);
  }
  .show-off {
    @include background-style1;
    transition: all 0.6s ease;
    transform: translateY(-100vh);
  }
}
