@import "../../../variable.scss";

.s-about {
  @include background-style1;

  max-width: 1400px;
  min-height: 100dvh;
  margin: 9.375rem auto;
  background-color: $white;
  &__title {
    @include titleH2(2.8rem);
    text-align: center;
    padding: 4.5rem 0 2rem;
  }
  &__container {
    padding: 5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1.1rem;
  }
  &__h3 {
    font-size: 1.8rem;
    margin-top: 4rem;
    margin-bottom: 1.5rem;
  }
  &__paragraph {
    font-size: 1.2rem;
    line-height: 2rem;
  }
  &__link {
    margin-top: 4rem;
  }
  &__link a {
    font-size: 1.4rem;
    color: $primary-color;
    transition: all 1s;
    font-weight: 500;
  }
  &__link a:hover {
    font-size: 1.4rem;
    color: $primary-color-dark3;
  }
  &__image-container {
    width: 100%; // Make the image occupy 100% of the container
  }

  &__col-1 {
    flex: 0 0 60%;
  }
  &__col-2 {
    flex: 0 0 40%;
  }
  &__image {
    width: 100%;
    height: auto;
  }

  @media (max-width: 940px) {
    &__container {
      padding: 0rem;
      width: 80%;
      margin: auto;
      gap: 4rem;
      flex-direction: column; // Return to column on smaller screens
    }
    &__title {
      font-size: 2.2rem;
      letter-spacing: 4px;
      padding-top: 2.5rem;
    }
    &__h3 {
      margin-top: 2rem;
    }
    &__link {
      margin-top: 2rem;
    }
    &__col-1 {
      flex: 0 0 60%;
    }

    &__col-2 {
      flex: 0 0 40%;
    }
    &__image-container {
      margin-bottom: 3rem;
    }
  }
}
