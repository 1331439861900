@import "../../../variable.scss";

.our-products {
  max-width: 1400px;
  margin: auto;
  position: relative;

  &__svg {
    position: absolute;
    right: 0;
    bottom: -23rem;
    width: 41rem;
    z-index: -1;
  }
  &__header {
    display: flex;
    flex-direction: column;
    gap: 25px;
    max-width: 90dvw;
    margin: auto;
  }
  &__title {
    @include titleH2(3.5rem);
  }
  &__h3 {
    font-size: 2rem;
    letter-spacing: 4px;
  }
  &__wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  &__p {
    font-size: 1.6rem;
    max-width: 550px;
    font-weight: 100;
    margin-top: 7rem;
    line-height: 1.5;
  }
  &__image-container {
    width: 18rem;
    height: auto;
    z-index: 5;
    perspective: 1000px;
  }
  &__image {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    animation: ocean-motion 10s ease-in-out infinite alternate;
  }
  &__shadow-image {
    position: absolute;
    top: 63%;
    right: -7%;
    width: 20rem;
    height: 5rem;
    background: rgba(#008fe4, 0.8);
    border-radius: 50%;
    transform-origin: bottom;
    transform: rotateX(80deg);
    box-shadow: 0px -5px 10px rgba($color: $primary-color, $alpha: 0.8);
  }
}

@media (max-width: 1300px) {
  .our-products {
    width: 80dvw;
    &__title {
      font-size: 3rem;
      letter-spacing: 5px;
    }
    &__h3 {
      font-size: 1.8rem;
      letter-spacing: 2px;
    }
    &__p {
      font-size: 1.3rem;
      margin-top: 4rem;
    }
  }
}

@media (max-width: 1130px) {
  .our-products {
    &__wrapper {
      flex-direction: column;
      row-gap: 4rem;
    }
  }
}

@media (max-width: 900px) {
  .our-products {
    width: 80dvw;
    &__title {
      font-size: 2.5rem;
      letter-spacing: 4px;
    }
    &__h3 {
      font-size: 1.7rem;
      letter-spacing: 2px;
    }
    &__p {
      font-size: 1.1rem;
      margin-top: 2rem;
    }
  }
}
@media (max-width: 520px) {
  .our-products {
    width: 90dvw;
    &__title {
      font-size: 2rem;
      letter-spacing: 3px;
      text-align: center;
    }
    &__h3 {
      font-size: 1.2rem;
      letter-spacing: 1px;
    }
    &__p {
      font-size: 1.1rem;
      margin-top: 0rem;
    }
  }
}
@keyframes ocean-motion {
  0% {
    transform: translateX(0) translateY(0) rotateZ(0deg);
  }
  50% {
    transform: translateX(0) translateY(5px) rotateZ(2deg); /* Upward movement with slight rotation */
  }
  100% {
    transform: translateX(0) translateY(0) rotateZ(-2deg); /* Return to original position */
  }
}
