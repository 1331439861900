@import "../../../variable.scss";

.annual-statistic {
  color: $white;
  font-size: 3.2rem;
  background-image: linear-gradient(
      to right,
      rgba($primary-color, 0.58),
      rgb(0, 0, 0)
    ),
    url("../../../img/importation.jpg");
  background-position: center center;
  background-size: cover;
  padding-bottom: 4.5rem;
  &__container {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  &__counter-index {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  &__container__col-title {
    padding: 5rem 0 2rem;
  }
  &__title {
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 0.2rem;
    // color: ;
    text-align: center;
    padding: 3rem 0 1rem;
  }

  &__value {
    background: rgba(255, 255, 255, 0.2);
    border: 2px solid $white;
    border-radius: 100%;
    color: $white;
    display: flex;
    flex-direction: column;
    font-size: 3.5rem;
    font-weight: 700;
    justify-content: center;
    height: 180px;
    margin: 0 auto 20px;
    width: 180px;
  }
  &__container-stats {
    display: flex;
    gap: 4rem;
    flex-wrap: wrap;
    padding: 0 2rem;
    text-align: center;
  }
  &__container-stats > * {
    flex: 1;
    column-gap: 2rem;
  }
  &__value span::after {
    font-size: 1.8rem;
    content: "+";
    vertical-align: top;
  }
  &__video-promo {
    background: transparent;
    padding: 1.2rem 1.5rem;
    border-radius: 20px;
    border: 2px white solid;
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }
  &__svg-container {
    background: black;
    padding: 1.5rem 1.5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__svg-container::after {
    content: "";
    position: absolute;
    top: 50%; /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */

    transform: translate(50%, -50%);
    margin-left: auto;
    margin-right: auto;

    border-radius: 50%;
    width: 9rem;
    height: 9rem;
    background: rgba(black, 0.3);
    opacity: 0;
    animation: waves 1500ms ease-out infinite;
  }
  &__video-link {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
  }
  &__play-icon {
    z-index: 2;
  }
  &__container-partners {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8rem;
    margin-top: 6rem;
    flex-wrap: wrap;
  }
  &__svg-logo {
    width: 8rem;
    height: 4rem;
  }
}

@media (max-width: 1710px) {
  .annual-statistic {
    &__container {
      // background: red;
      flex-direction: column;
      justify-content: center;
      gap: 50px;
    }
    &__value {
      font-size: 2.5rem;
      font-weight: 700;
      justify-content: center;
      height: 7.5rem;
      margin: 0 auto 10px;
      width: 7.5rem;
    }
    &__container-stats {
      justify-content: center;
      gap: 2rem;
    }
  }
}

@media (max-width: 880px) {
  .annual-statistic {
    &__container-partners {
      margin-top: 4rem;
    }
    &__name {
      font-size: 0.9rem;
    }
  }
}

@media (max-width: 678px) {
  .annual-statistic {
    &__container {
      padding: 2rem 0;
      width: 90dvw;
      margin: auto;
    }
    &__container__col-title {
      padding: 0 0.5rem;
    }
    &__container-stats {
      // margin-top: 3.5rem;
      flex-direction: column;
      justify-content: center;
    }
    &__title {
      font-size: 1.4rem;
    }
    &__value {
      font-size: 1.6rem;
    }
    &__value span::after {
      font-size: 1rem;
    }
    &__container-partners {
      margin-top: 0;
      padding: 0 0.4rem 0;
    }
    &__svg-logo {
      width: 6rem;
      height: 3rem;
    }
  }
}

@keyframes waves {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
